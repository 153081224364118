import React from 'react';

import clsx from 'clsx';

import { Icon } from '@ui/atoms/icon';

import { SquareArrowButtonProps } from './square-arrow-button.interface';

import s from './square-arrow-button.module.scss';

export const SquareArrowButton = ({
	className,
	...rest
}: SquareArrowButtonProps): JSX.Element => (
	<button type='button' className={clsx(s.container, className)} {...rest}>
		<Icon name='arrow-down' className={s.icon} />
	</button>
);
