import React from 'react';

import clsx from 'clsx';

import { Icon } from '@ui/atoms/icon';
import { TextArrowButton } from '@ui/molecules/text-arrow-button';

import { HeaderProps } from './header.interface';

import s from './header.module.scss';

export const Header = ({ className }: HeaderProps): JSX.Element => (
	<header className={clsx(s.container, className)}>
		<Icon name='profluent' className={s.icon} />

		<a target='_blank' rel='noreferrer' href='https://profluenttrading.zohorecruit.com/jobs/Careers'>
			<TextArrowButton text='Careers' />
		</a>
	</header>
);
