import React from 'react';

import { useSiteMetadata } from './seo-tags.hooks';

import { SEOTagsProps } from './seo-tags.interface';

export const SEOTags = ({
	title,
	description,
	siteUrl,
	children,
}: SEOTagsProps): JSX.Element => {
	const metadata = useSiteMetadata();

	return (
		<>
			<title>{title ?? metadata?.title!}</title>
			<meta name='description' content={description ?? metadata?.description!} />
			<meta name='twitter:title' content={title ?? metadata?.title!} />
			<meta name='twitter:url' content={siteUrl ?? metadata?.siteUrl!} />
			<meta
				name='twitter:description'
				content={description ?? metadata?.description!}
			/>
			{children}
		</>
	);
};
