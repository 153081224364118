import { graphql, useStaticQuery } from 'gatsby';

import { SEOMetadata } from './seo-tags.interface';

export const useSiteMetadata = (): { [P in keyof SEOMetadata]: SEOMetadata[P] | null } => {
	const { site } = useStaticQuery<Queries.SEOMetadataHookQuery>(graphql`
		query SEOMetadataHook {
			site {
				siteMetadata {
					title
					description
					siteUrl
				}
			}
		}
	`);

	return site!.siteMetadata!;
};
