import React from 'react';

import clsx from 'clsx';

import { Text } from '@ui/atoms/text';

import { WindowProps } from './window.interface';

import s from './window.module.scss';

export const Window = ({ children, title, className }: WindowProps) => (
	<div className={clsx(s.window, className)}>
		{title && <Text variant='body3' text={title} className={s.title} />}

		{children}
	</div>
);
