import React, { forwardRef } from 'react';

import { QNA } from './qna';

import s from './who-we-are.module.scss';

export const WhoWeAre = forwardRef<HTMLElement, {}>((_, ref): JSX.Element => (
	<section ref={ref} className={s.container}>
		<QNA title='Who we are?'>
			Founded in 2013, Profluent Group is a Puerto-Rico based investment manager
			that specializes in using advanced artificial intelligence (AI) and
			machine learning technology in order to produce uncorrelated alpha.
			Profluent manages its own capital and, at times, exports investment
			management services across the globe.
		</QNA>

		<QNA title='What we do?'>
			<p>
				Finance has become increasingly computerized, as most of the volume on
				the exchanges is accounted for by algorithmic trading. Profluent goes
				one step beyond merely automating trading: Profluent developed a
				completely integrated artificially intelligent portfolio management
				system. As such, Profluent’s business model is premised on complete
				algorithmic automation of the investment process.
			</p>

			<p>
				Utilizing high-performance computing, Profluent’s algorithms
				automatically analyze various portfolios, select which instruments to
				trade, and design superior trading systems for any and all markets.
			</p>
		</QNA>
	</section>
));
