import React from 'react';

import clsx from 'clsx';

import { Text } from '@ui/atoms/text';

import { Window } from '../window.component';

import { InfoWindowProps } from './info-window.interface';

import s from './info-window.module.scss';

export const InfoWindow = ({ title, children, className }: InfoWindowProps): JSX.Element => (
	<Window className={clsx(s.container, className)}>
		<Text variant='h3' text={title} />

		<Text variant='body3'>
			{children}
		</Text>
	</Window>
);
