import { Window as Base } from './window.component';
import { HeaderWindow as Header } from './header-window';
import { IconWindow as Icon } from './icon-window';
import { ImageWindow as Image } from './image-window';
import { InfoWindow as Info } from './info-window';

import type { WindowComponent } from './window.interface';

const Window = Base as WindowComponent;

Window.Header = Header;
Window.Icon = Icon;
Window.Image = Image;
Window.Info = Info;

export { Window };
