import React from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';

import clsx from 'clsx';

import { Window } from '../window.component';

import { ImageWindowProps } from './image-window.interface';

import s from './image-window.module.scss';

export const ImageWindow = ({ className, title, ...rest }: ImageWindowProps): JSX.Element => (
	<Window title={title} className={clsx(s.container, className)}>
		<GatsbyImage {...rest} />
	</Window>
);
