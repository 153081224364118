import React from 'react';

import clsx from 'clsx';

import { Text } from '@ui/atoms/text';

import { Window } from '../window.component';

import { HeaderWindowProps } from './header-window.interface';

import s from './header-window.module.scss';

export const HeaderWindow = ({ className, ...rest }: HeaderWindowProps): JSX.Element => (
	<Window className={clsx(s.container, className)}>
		<Text variant='h2' {...rest} />
	</Window>
);
