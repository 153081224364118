import React from 'react';

import { IconName } from './icon.interface';

import CloseButton from './assets/close-button.svg';
import ArrowDown from './assets/arrow-down.svg';
import ArrowRight from './assets/arrow-right.svg';
import Profluent from './assets/profluent.svg';
import Twitter from './assets/twitter.svg';

export const mapIconNameToComponent: Record<IconName, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
	'close-button': CloseButton,
	'arrow-down': ArrowDown,
	'arrow-right': ArrowRight,
	'profluent': Profluent,
	'twitter': Twitter,
};
