import React, { useCallback, useRef } from 'react';

import { Header } from '@widgets/header';
import { Footer } from '@widgets/footer';

import { Introduction } from './fragments/introduction';
import { WhoWeAre } from './fragments/who-we-are';
import { Strategies } from './fragments/strategies';
import { Founder } from './fragments/founder';

import s from './main.module.scss';

export const MainView = () => {
	const whoWeAreRef = useRef<HTMLElement>(null);

	const scrollToSection = useCallback(() => {
		whoWeAreRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, []);

	return (
		<main className={s.main}>
			<Header />
			<Introduction onNavigate={scrollToSection} />
			<WhoWeAre ref={whoWeAreRef} />
			<Strategies />
			<Founder />
			<Footer />
		</main>
	);
};
