import React from 'react';

import { Text } from '@ui/atoms/text';
import { Window } from '@ui/organisms/window';

import s from './strategies.module.scss';

export const Strategies = (): JSX.Element => (
	<section>
		<Text variant='h2' text='Strategies Deployed' className={s.header} />

		<div className={s.grid}>
			<Window.Info title='Market making'>
				Market making in interest rate futures, equity index futures, volatility
				futures, as well as foreign exchange and cryptocurrencies
			</Window.Info>

			<Window.Info title='Directional trading'>
				Intraday directional trading in US, EU, and Asian equities. Directional
				trading in global equities. Non-directional options trading
			</Window.Info>

			<Window.Info title='Statistical arbitrage'>
				Statistical arbitrage of both stocks & futures
			</Window.Info>

			<Window.Info title='Investing'>
				Investing in crypto derivatives and asset-backed securities
			</Window.Info>
		</div>
	</section>
);
