import React from 'react';

import { Text } from '@ui/atoms/text';
import { SquareArrowButton } from '@ui/molecules/square-arrow-button';

import { IntroductionProps } from './introduction.interface';

import s from './introduction.module.scss';

export const Introduction = ({ onNavigate }: IntroductionProps): JSX.Element => (
	<section className={s.section}>
		<span className={s.heading}>
			<Text variant='h1' text='Profluent' />
			<Text variant='h1' text='Group' className={s.secondWord} />
		</span>

		<div className={s.body}>
			<SquareArrowButton onClick={onNavigate} className={s.button} />

			<Text variant='body1'>
				Puerto-Rico-based high-frequency proprietary
				trading&nbsp;&&nbsp;market-making firm specializing in algorithmic money
				management across digital assets, equities, futures, commodities, FX,
				and other financial products
			</Text>
		</div>
	</section>
);
