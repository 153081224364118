import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { Text } from '@ui/atoms/text';
import { Window } from '@ui/organisms/window';

import s from './founder.module.scss';

export const Founder = (): JSX.Element => {
	const data = useStaticQuery(graphql`
		query FounderImage {
			file(name: { eq: "founder" }) {
				id
				name
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`);

	return (
		<section className={s.container}>
			<Text variant='h2' className={s.header}>
				Founder & CIO
			</Text>

			<div className={s.aside}>
				<Window.Image
					title='bert.jpg'
					alt='Bert'
					image={data.file.childImageSharp.gatsbyImageData}
					className={s.image}
				/>

				<a
					target='_blank'
					rel='noreferrer'
					href='https://twitter.com/BMouler'
					className={s.twitter}
				>
					<Window.Icon icon='twitter' />
				</a>
			</div>

			<div className={s.content}>
				<p>
					<Text variant='body2'>
						<strong>Bert</strong> has been developing algorithmic trading
						systems since 2010. He is the principal researcher at Profluent
						Group and the chief designer of the intelligent portfolio management
						software we use to manage funds.
					</Text>
				</p>

				<p>
					<Text variant='body2'>
						He received three undergraduate degrees from UC Santa Barbara and
						continued to complete his Master&apos;s, with highest honors in
						bioinformatics, at The Johns Hopkins University. He is currently
						pursuing the Georgia Tech OMSCS degree. Bert is involved in a
						variety of technology start-ups, as well as AI and machine learning
						projects around the world.
					</Text>
				</p>
			</div>
		</section>
	);
};
