import React from 'react';

import clsx from 'clsx';

import { Text } from '@ui/atoms/text';
import { Window } from '@ui/organisms/window';

import { QNAProps } from './qna.interface';

import s from './qna.module.scss';

export const QNA = ({ title, children, className }: QNAProps): JSX.Element => (
	<div className={clsx(s.container, className)}>
		<Window.Header text={title} className={s.header} />

		<Text variant='body2' className={s.content}>
			{children}
		</Text>
	</div>
);
