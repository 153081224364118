import React from 'react';

import clsx from 'clsx';

import { Icon } from '@ui/atoms/icon';
import { Text } from '@ui/atoms/text';
import { Window } from '@ui/organisms/window';

import { FooterProps } from './footer.interface';

import s from './footer.module.scss';

export const Footer = ({ className }: FooterProps): JSX.Element => (
	<footer className={clsx(s.container, className)}>
        <Window className={s.copyright}>
            <Icon name='profluent' className={s.logo} />

            <Text variant='h4'>
                <span className={s.company}>Profluent&nbsp;Group</span> 2018-2022
            </Text>
        </Window>

        <Window.Info title='Disclaimer'>
            Past performance does not guarantee future results. This website and the
            content listed herein is not a solicitation to purchase or sell
            securities. Rather, this website is for informational purposes only.
            Inquiries to Profluent Group can be directed at{' '}
            <a href='mailto:help@profluentgroup.com' className={s.mail}>help@profluentgroup.com</a>
        </Window.Info>
    </footer>
);
