import React from 'react';

import clsx from 'clsx';

import { Icon } from '@ui/atoms/icon';
import { Text } from '@ui/atoms/text';

import { TextArrowButtonProps } from './text-arrow-button.interface';

import s from './text-arrow-button.module.scss';

export const TextArrowButton = ({ text, onClick, className }: TextArrowButtonProps): JSX.Element => (
	<button type='button' onClick={onClick} className={clsx(s.container, className)}>
		<span className={s.arrowWrapper}>
			<Icon name='arrow-right' className={s.arrow} />
		</span>

		<Text variant='body1' text={text} />
	</button>
);
