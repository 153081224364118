import React from 'react';

import clsx from 'clsx';

import { HeaderVariant, TextProps } from './text.interface';

import s from './text.module.scss';

export const Text = ({ text, children, variant, className }: TextProps): JSX.Element => {
	const SemanticText = variant.startsWith('h') ? variant as HeaderVariant : 'span';

	return (
		<SemanticText className={clsx(s.text, s[variant], className)}>
			{text ?? children}
		</SemanticText>
	);
};
