import React from 'react';

import { Icon } from '@ui/atoms/icon';

import { Window } from '../window.component';

import { IconWindowProps } from './icon-window.interface';

import s from './icon-window.module.scss';

export const IconWindow = ({ icon, className }: IconWindowProps): JSX.Element => (
	<Window className={className}>
		<div className={s.container}>
			<Icon name={icon} className={s.icon} />
		</div>
	</Window>
);
